import React, { useState } from 'react';
import { AdminLogin } from '../api/api';

const AdminLoginForm = ({ onLogin = null }) => {
    const [id, setId] = useState('');
    const [password, setOTP] = useState('');
    const [submitText, setSubmitText] = useState('SIGN IN')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id.trim() && password.trim()) {
            setSubmitText('Logging in...');
            setSubmitDisabled(true);
            try {
                await AdminLogin({ id: id, password: password });
                if (onLogin != null)
                    onLogin();
            } catch (error) {
                console.error('Error in Login:', error.response ? error.response.data : error.message);
                setSubmitText('SIGN IN');
                setSubmitDisabled(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ minWidth: 200 + 'px' }}>
            <div className="form-floating mb-2">
                <input
                    type="tel"
                    id="login-id-input"
                    className="form-control"
                    aria-describedby="idHelp"
                    value={id}
                    placeholder="Admin Id"
                    required={true}
                    onChange={(e) => setId(e.target.value)}
                />
                <label className="form-label">Admin Id</label>
            </div>
            <div className="form-floating mb-2">
                <input
                    type="number"
                    id="login-id-password-input"
                    className="form-control"
                    value={password}
                    placeholder="Password"
                    required={true}
                    onChange={(e) => setOTP(e.target.value)}
                />
                <label className="form-label">Password</label>
            </div>
            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={submitDisabled}>{submitText}</button>
        </form>
    );
}

export default AdminLoginForm;