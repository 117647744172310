import Banner from "../components/Banner";
import { useEffect } from "react";

const About = ({ setCityIndex, cities = [], cityIndex = 0 }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "DocInJob | About";
    }, []);

    return (
        <div>
            <Banner setCityIndex={setCityIndex} cities={cities} cityIndex={cityIndex} image="assets/Banner-2.png" />
            <div className="container col-xxl-10 px-4 py-3">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6 mt-0">
                        <img src="assets/side-image.png" className="d-block mx-lg-auto img-fluid" alt="Quality healthcare made easy" width="700" height="500" loading="lazy" />
                    </div>
                    <div className="col-lg-6 mt-0">
                        <h1 className="display-5 fw-bold text-primary lh-1 mb-3">Quality healthcare made easy</h1>
                        <p>Our Mission : DocInJob is on a mission to make quality healthcare affordable and accessible for over a 4 Lakhs + Indians. We believe in empowering our users with the most accurate, comprehensive, and curated information and care, enabling them to make better healthcare decisions.
                        </p>
                        <p className="mb-0">Health is a habit :</p>
                        <p>
                            It is the journey that takes you to new destinations every day with endless possibilities of life on the back of happiness, energy, and hope. DocInJob wants to make this journey easy for every Indian and help them live healthier and longer lives.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container col-xxl-10 px-4 py-3">
                <h1 className="text-center display-4 fw-bold text-primary">Each time a patient finds the right doctor, we build a healthier nation</h1>
                <h2 className="text-center">A step by step guide to book an on-demand appointment</h2>
                <div className="my-5 d-flex flex-column flex-lg-row text-center text-lg-start flex-wrap align-items-start justify-content-center p-4">
                    <div className="col col-lg-4 p-3">
                        <div className="text-center">
                            <img style={{ maxWidth: 200 + "px" }} alt="connect" src="assets/connect-icon.png" />
                            <h5 className="mt-3 text-primary">CONNECT</h5>
                            <p>We understand healthcare goes beyond signs, symptoms, diagnosis, and treatment. It’s about the deep connection between doctors and patients that leads to continuous care and sustained, better outcomes.</p>
                        </div>
                    </div>
                    <div className="col col-lg-4 p-3">
                        <div className="text-center">
                            <img style={{ maxWidth: 200 + "px" }} alt="trust" src="assets/trust-icon.png" />
                            <h5 className="mt-3 text-primary">TRUST</h5>
                            <p>DocInJob works on trust. We are aware of the responsibility placed on us by patients and doctors. We always have and always will do everything we possibly can to uphold this trust.</p>
                        </div>
                    </div>
                    <div className="col col-lg-4 p-3">
                        <div className="text-center">
                            <img style={{ maxWidth: 200 + "px" }} alt="transparency" src="assets/transparency-icon.png" />
                            <h5 className="mt-3 text-primary">TRANSPARENCY</h5>
                            <p>We believe in full disclosure. We believe in communicating openly and honestly, and holding ourselves to the highest ethical standards.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;