import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const SearchBar = ({ setCityIndex, cities = [], cityIndex = 0 }) => {
    const [query, setQuery] = useState('');
    const [city, setCity] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (query.trim()) {
            navigate(`/search/?docSpe=${query}&city=${city}`);
        }
    };

    useEffect(() => {
        if (cityIndex < cities.length && cityIndex >= 0) {
            setCity(cities[cityIndex]);
        }
    }, [setCity, cities, cityIndex]);

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-wrap mx-2 px-0 px-sm-3 px-md-4 px-lg-5 gap-2" role="search">
            <div className="col-12 col-sm input-group">
                <i className="input-group-text bi bi-geo-alt"></i>
                <input
                    list="cities"
                    type="text"
                    aria-label="Location"
                    placeholder="Location"
                    className="form-control"
                    value={city}
                    required={true}
                    onChange={(e) => {
                        setCityIndex(cities.indexOf(e.target.value));
                    }}
                />
                <datalist id="cities">
                    {cities.map((city, index) => (
                        <option key={index} value={city} />
                    ))}
                </datalist>
                <button className="btn btn-outline-accent" type="button" onClick={() => setCityIndex(0)}>
                    <i className="bi bi-backspace"></i>
                </button>
            </div>

            <div className="col input-group">
                <input
                    type="search"
                    aria-label="Search"
                    placeholder="Search"
                    className="form-control"
                    value={query}
                    required={true}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <button className="btn btn-outline-accent" type="button" onClick={() => setQuery('')}>
                    <i className="bi bi-backspace"></i>
                </button>
                <button className="btn btn-outline-accent" type="submit"><i className="bi bi-search"></i></button>
            </div>
        </form>
    );
};

export default SearchBar;