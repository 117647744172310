import React, { useState, useEffect, useCallback } from 'react';
import { GetAppointments } from '../api/api';
import AppointmentCard from './AppointmentCard';

const Appointments = () => {
    const [appointments, setAppointments] = useState([]);
    const [page, setPage] = useState([0, 1, 0]);

    const getAppointments = useCallback(async () => {
        if (page[0] >= page[1]) return;
        if (page[0] < page[1]) setPage([page[0] + 1, page[1], page[2]]);
        var response = await GetAppointments({ data: { page: page[0] } });
        console.log(response.appointments);
        setAppointments([...appointments, ...response.appointments]);
        setPage([response.pagination.currentPage, response.pagination.totalPages, response.pagination.totalAppointments]);
    }, []);

    useEffect(() => {
        getAppointments();
    }, [getAppointments]);

    return (
        <div className='d-flex flex-wrap align-items-stretch gap-2'>
            {appointments.length ?
                appointments.map((appointment, index) => (
                    <AppointmentCard key={`appointment-${index}`} appointment={appointment} />
                )) : (
                    <div>You have no appointments!</div>
                )
            }
            {
                page[0] < page[1] ? (
                    <button className='btn btn-primary' onClick={getAppointments}>Load More</button>
                ) : null
            }
        </div>
    );
}

export default Appointments;