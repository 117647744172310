import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { GetUser, GetAdminUser, GetCities, GetSpecialities } from "./api/api";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import User from "./pages/User";
import Search from "./pages/Search";
import Logo from "./components/Logo";
import AdminLayout from "./pages/AdminLayout";
import AdminLoginForm from "./components/AdminLoginForm";
import AdminDoctors from "./pages/AdminDoctors";
import AdminClinics from "./pages/AdminClinics";
import AdminClinic from "./pages/AdminClinic";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";


function App() {
  const [user, setUser] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const [cities, setCities] = useState(['']);
  const [cityIndex, setCityIndex] = useState(1);
  const [specialities, setSpecialities] = useState([]);

  const getUser = async () => {
    setUser(await GetUser());
  }

  const getAdminUser = async () => {
    setAdminUser(await GetAdminUser());
  }

  const getCities = async (city) => {
    setCities([''].concat(await GetCities(city)));
  }

  const getSpecialities = async (city) => {
    setSpecialities(await GetSpecialities(city));
  }

  useEffect(() => {
    getUser();
    getAdminUser();
    getCities();
    getSpecialities();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout getUser={getUser} user={user} />}>
          <Route index element={<Home user={user} setCityIndex={setCityIndex} cities={cities} cityIndex={cityIndex} />} />
          <Route path="about" element={<About setCityIndex={setCityIndex} cities={cities} cityIndex={cityIndex} />} />
          <Route path="user" element={<User user={user} getUser={getUser} />} />
          <Route path="search" element={<Search user={user} setCityIndex={setCityIndex} cities={cities} cityIndex={cityIndex} specialities={specialities} />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        </Route>
        <Route path="admin" element={<AdminLayout adminUser={adminUser} getAdminUser={getAdminUser} />}>
          {adminUser == null ? (
            <Route index element={
              <div className="d-flex flex-wrap align-items-stretch justify-content-center gap-5 my-5 py-5">
                <div className="px-3 px-sm-5 w-100 d-flex align-items-center" style={{maxWidth: 500 + "px"}}>
                  <Logo width={100} widthUnit="%" />
                </div>
                <div className="px-3 px-sm-5 w-100" style={{maxWidth: 500 + "px"}}>
                  <AdminLoginForm onLogin={getAdminUser} />
                </div>
              </div>
            } />
          ) : (
            <Route index element={<User user={user} getUser={getAdminUser} />} />
          )}
          <Route path="doctors" element={<AdminDoctors />} />
          <Route path="clinics" element={<AdminClinics />} />
          <Route path="clinic" element={<AdminClinic />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
