import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { GetSearch } from '../api/api';

const SearchForm = ({ setResults, setPage, setPages, setShown, setTotal, setCityIndex, setText, results = [], page = 1, cities = [], cityIndex = 0, specialities = [] }) => {
    // const [days, setDays] = useState([]);
    // const [dayTimes, setDayTimes] = useState([]);
    const [newResults, setNewResults] = useState([]);

    const [searchParams] = useSearchParams();
    const [experience, setExperience] = useState(searchParams.get("experience") ?? "5");
    const [gender, setGender] = useState(searchParams.get("gender") ?? "");
    const [fee, setFee] = useState(searchParams.get("fee") ?? "");
    const [city, setCity] = useState(searchParams.get("city") ?? cities[cityIndex] ?? '');
    const [speciality, setSpeciality] = useState(searchParams.get("speciality") ?? '');
    const [docSpe, setDocSpe] = useState(searchParams.get("docSpe") ?? "");

    const navigate = useNavigate();

    // const updateDays = (e) => {
    //     if (e.target.checked)
    //         var arr = days + [e.target.value];
    //     setDays(arr);
    // }

    // const updateDayTimes = (e) => {
    //     if (e.target.checked)
    //         var arr = dayTimes + [e.target.value];
    //     setDayTimes(arr);
    // }

    useEffect(() => {
        searchParams.set("city", city);
        searchParams.set("speciality", speciality);
        searchParams.set("docSpe", docSpe);
        searchParams.set("experience", experience);
        searchParams.set("gender", gender);
        searchParams.set("fee", fee);

        navigate({ search: searchParams.toString() });
    }, [city, speciality, docSpe, experience, fee, gender, searchParams, navigate, page]);

    useEffect(() => {
        if (newResults) {
            var totalResults = [];
            if (page === 1)
                totalResults = newResults;
            else
                totalResults = results.concat(newResults)
            setNewResults(null);
            setResults(totalResults);
        }
        setShown(results.length);
    }, [results, newResults, setResults, setShown, page]);

    useEffect(() => {
        const Search = async () => {
            setText('Searching...');

            var data = {};
            data.page = page;
            if (city.trim()) data.city = city;
            if (speciality.trim()) data.speciality = speciality;
            if (docSpe.trim()) data.docSpe = docSpe;
            if (experience.trim()) data.experience = parseInt(experience);
            if (gender.trim()) data.gender = gender;
            if (fee.trim()) data.fee = parseInt(fee);

            var response = await GetSearch({ data: data })

            if (page !== response.currentPage)
                setPage(response.currentPage);
            setNewResults(response.results);
            setPages(response.totalPages);
            setTotal(response.totalResults);
            setText('No Results. Update your Serach filters and try again.');
        };

        Search();
    }, [city, docSpe, experience, fee, gender, page, setPage, setPages, setText, setTotal, speciality]);

    return (
        <div>
            <form method="GET" style={{ minWidth: 300 + 'px' }}>
                <div className="d-flex flex-wrap gap-2">
                    <div className="col col-md-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input
                                    list="cities"
                                    type="text"
                                    id="search-location-input"
                                    className="form-control"
                                    name="city"
                                    value={city}
                                    placeholder="City"
                                    required={true}
                                    onChange={(e) => { setCity(e.target.value); setCityIndex(cities.indexOf(e.target.value)); }}
                                />
                                <datalist id="cities">
                                    {cities.map((city, index) => (
                                        <option key={"city-" + index} value={city} />
                                    ))}
                                </datalist>
                                <label className="form-label">City</label>
                            </div>
                            <button className="btn btn-outline-accent" type="button" onClick={() => { setCity(''); setCityIndex(0); }}>
                                <i className="bi bi-backspace"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input
                                    list="specialities"
                                    type="text"
                                    id="search-speciality-input"
                                    className="form-control"
                                    name="speciality"
                                    value={speciality}
                                    placeholder="Speciality"
                                    onChange={(e) => setSpeciality(e.target.value)}
                                />
                                <datalist id="specialities">
                                    {specialities.map((speciality, index) => (
                                        <option key={"speciality-" + index} value={speciality} />
                                    ))}
                                </datalist>
                                <label className="form-label">Speciality</label>
                            </div>
                            <button className="btn btn-outline-accent" type="button" onClick={() => setSpeciality('')}>
                                <i className="bi bi-backspace"></i>
                            </button>
                        </div>
                    </div>
                    <div className='col-12 col-md'>
                        <div className="input-group">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    id="search-docSpe-input"
                                    className="form-control"
                                    name="docSpe"
                                    value={docSpe}
                                    placeholder="Search Doctor or Speciality"
                                    onChange={(e) => setDocSpe(e.target.value)}
                                />
                                <label className="form-label">Search Doctor or Speciality</label>
                            </div>
                            <button className="btn btn-outline-accent" type="button" onClick={() => setDocSpe('')}>
                                <i className="bi bi-backspace"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <label className="form-label fs-5 mt-2">More Filters</label>
                <div className="d-flex flex-wrap gap-2 mb-2">
                    <div className="col-12 col-sm-4 col-md form-floating">
                        <select
                            id="search-experience-input"
                            className="form-select"
                            name="experience"
                            placeholder="Experience"
                            onChange={(e) => setExperience(e.target.value)}
                            defaultValue={experience}
                        >
                            <option value="5">Above 5 Years</option>
                            <option value="10">Above 10 Years</option>
                            <option value="15">Above 15 Years</option>
                            <option value="20">Above 20 Years</option>
                            {experience !== "5" && experience !== "10" && experience !== "15" && experience !== "20" ? <option value={experience}>Above {experience} Years</option> : <></>}
                        </select>
                        <label className="form-label">Experience</label>
                    </div>
                    <div className="col-12 col-sm form-floating">
                        <select
                            id="search-fee-input"
                            className="form-select"
                            name="fee"
                            placeholder="Fee"
                            onChange={(e) => setFee(e.target.value)}
                            defaultValue={fee}
                        >
                            <option value="">Any</option>
                            <option value="500">Under ₹500</option>
                            <option value="1000">Under ₹1000</option>
                            <option value="1500">Under ₹1500</option>
                            <option value="2000">Under ₹2000</option>
                            {fee !== "" && fee !== "500" && fee !== "1000" && fee !== "1500" && fee !== "2000" ? <option value={fee}>Under ₹{fee}</option> : <></>}
                        </select>
                        <label className="form-label">Fee</label>
                    </div>
                    <div className="col form-floating">
                        <select
                            id="search-gender-input"
                            className="form-select"
                            name="gender"
                            placeholder="Gender"
                            onChange={(e) => setGender(e.target.value)}
                            defaultValue={gender}
                        >
                            <option value="">Any</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        <label className="form-label">Gender</label>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SearchForm;