import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Layout = ({ getUser, user = null }) => {
    const nav_items = [
        ["Home", "/"],
        ["Book Appointment", "/search"],
        ["Book Test", "/#lab-test"],
        ["Surgery", "/#surgery"],
        ["Order Medicine", "/#medicine"],
        ["About Us", "/about"],
    ];

    return (
        <>
            <header className="App-header fixed-top">
                <Navbar getUser={getUser} items={nav_items} user={user} />
            </header>

            <div style={{marginTop: 7 + "rem"}}>
                <Outlet />
            </div>

            <Footer nav_items={nav_items} />
        </>
    )
};

export default Layout;