import React, { useState, useEffect, useCallback } from 'react';
import Result from "./Result";
import { BookAppointment, AppointmentPayment } from '../api/api';

const BookResult = ({ result, user = null }) => {
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [reason, setReason] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientAge, setPatientAge] = useState("");
    const [patientGender, setPatientGender] = useState("");
    const [submitText, setSubmitText] = useState('Book Now')
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [dateSlots, setDateSlots] = useState([]);

    useEffect(() => {
        const input = result.timings;

        function formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }

        function formatReadableDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = date.toLocaleString('en-US', { month: 'short' });
            return `${day} ${month}`;
        }

        function parseTimeRange(timeString) {
            const [day, ...rest] = timeString.split(" | ");
            const timeRange = rest.slice(0, rest.length - 1).join(" "); // Join time ranges
            const id = rest[rest.length - 1]; // Get the last part as ID
            return { day, timeRange, id }; // Return day, timeRange, and ID
        }

        const dayIndexMap = {
            Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6
        };

        const timeSlots = input.split(',').map(slot => parseTimeRange(slot.trim()));

        function generateNextSevenDaysOptions() {
            const options = [];
            const currentDate = new Date();

            for (let i = 1; i < 8; i++) {
                const futureDate = new Date();
                futureDate.setDate(currentDate.getDate() + i);

                const dayOfWeek = futureDate.getDay();
                const weekDayName = futureDate.toLocaleDateString('en-US', { weekday: 'short' });

                const matchingSlots = timeSlots
                    .filter(slot => dayIndexMap[slot.day] === dayOfWeek)
                    .map(slot => ({
                        timeRange: slot.timeRange,
                        id: slot.id
                    }));

                options.push({
                    date: formatDate(futureDate),
                    readableDate: formatReadableDate(futureDate),
                    weekday: weekDayName,
                    time: matchingSlots
                });
            }

            return options;
        }

        setDateSlots(generateNextSevenDaysOptions());
    }, [result]);

    useEffect(() => {
        setPatientName(user ? user.name ?? "" : "");
        setPatientAge(user ? user.age ?? "" : "");
        setPatientGender(user ? user.gender ?? "" : "");

        if (dateSlots.length >= 7)
            for (let i = 0; i < 7; i++) {
                if (dateSlots[i].time.length > 0) {
                    setSelectedDate(dateSlots[i].date);
                    setTimeSlots(dateSlots[i].time);
                    setSelectedTime(dateSlots[i].time[0]);
                    break;
                }
            }
    }, [user, dateSlots]);

    const handleBooking = async () => {
        setSubmitText('Booking...');
        setSubmitDisabled(true);
        try {
            var response = await BookAppointment({
                data: {
                    doctorId: result.doctorId,
                    clinicId: result.clinicId,
                    reason: reason,
                    date: selectedDate,
                    timeVisited: selectedTime.timeRange,
                    patientName: patientName,
                    patientAge: patientAge,
                    patientGender: patientGender
                }
            });
            response = await AppointmentPayment({ params: { appId: response._id, policy_name: 'xyz' } });
            window.location.href = response.short_url;
        } catch (error) {
            console.error('Error in BookAppointment:', error.response ? error.response.data : error.message);
            setSubmitText('Book Now');
            setSubmitDisabled(false);
        }
    };

    return (
        <div>
            <div className="container px-3">
                <Result result={result} />
            </div>
            <div className="container px-3">
                <div className="my-5">
                    <h3>Select Day</h3>
                    <div className="d-flex flex-wrap gap-3">
                        {dateSlots.map((day, index) => (
                            <button
                                key={`day-${index}`}
                                onClick={() => { setSelectedDate(day.date); setTimeSlots(day.time); setSelectedTime(day.time[0]); }}
                                className={day.date === selectedDate ? "btn btn-primary" : "btn btn-outline-primary"}
                                disabled={day.time.length < 1}>
                                {day.weekday}, {day.readableDate}
                            </button>
                        ))}
                    </div>
                </div>
                {timeSlots.length > 0 ? (
                    <div className="my-5">
                        <h3>Select Time</h3>
                        <div className="d-flex flex-wrap gap-3">
                            {timeSlots.map((time, index) => (
                                <button
                                    key={`time-${index}`}
                                    onClick={() => { setSelectedTime(time); }}
                                    className={time === selectedTime ? "btn btn-primary" : "btn btn-outline-primary"}>
                                    {time.timeRange}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (<></>)}
                {selectedDate && selectedTime ? (
                    <div>
                        <div className="mb-3">
                            <label className='form-label'>Reason</label>
                            <textarea
                                className="form-control"
                                placeholder="Specify the purpose of your visit: Disease, Symptoms, Diagnosis, Problems etc. (Optional)"
                                style={{ height: 100 + "px" }}
                                value={reason}
                                onChange={(e) => { setReason(e.target.value) }}>
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Patient Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Patient Name"
                                value={patientName}
                                onChange={(e) => { setPatientName(e.target.value) }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Patient Age</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Patient Age"
                                value={patientAge}
                                onChange={(e) => { setPatientAge(e.target.value) }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Patient Gender</label>
                            <select
                                className="form-select"
                                defaultValue={patientGender}
                                onChange={(e) => { setPatientGender(e.target.value) }}
                            >
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        {user != null ? (
                            <button className='btn btn-lg btn-primary w-100' onClick={handleBooking} disabled={submitDisabled}>{submitText}</button>
                        ) : (
                            <a className='btn btn-lg btn-primary w-100' href={`/user?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`}>Book Now</a>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default BookResult;