import LoginForm from "../components/LoginForm";
import UserForm from "../components/UserForm";
import Appointments from "../components/Appointments.js";
import { useEffect } from "react";

const User = ({ user = null, getUser = null }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'DocInJob | ' + user?.name?? 'Login or Signup';
    }, [user]);

    return (
        <div className="d-flex align-items-center" style={{minHeight: 80 + "vh"}}>
            {user == null ? (
                <div className="d-flex w-100 flex-wrap align-items-stretch justify-content-center gap-3 my-5 py-5">
                    <div className="px-3" style={{ maxWidth: 800 + "px" }}>
                        <h4 className="mb-4">
                            Login or Signup with Us
                        </h4>
                        <LoginForm onLogin={getUser} />
                    </div>
                </div>
            ) : (
                <div className="my-5 container-sm px-2 px-md-5">
                    <h1 className="display-4 fw-bold my-4">Your Details</h1>
                    <div className="mb-5">
                        <UserForm user={user} getUser={getUser} />
                    </div>
                    <h1 id="appointments" className="display-4 fw-bold my-4">Your Appointments</h1>
                    <Appointments />
                </div>
            )}
        </div>
    );
};

export default User;